import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, params) {
      return useJwt.getInvoices(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getInvoiceAnalytics(params).then(response => response);
    },
    getInvoiceHtml(ctx, invoiceId) {
      return useJwt.getInvoiceHtml({ invoiceId }).then(response => response);
    },
    previewInvoice(ctx, invoiceId) {
      return useJwt.previewInvoice(invoiceId).then(response => response);
    },
    previewInvoiceWithUUID(ctx, params) {
      return useJwt.previewInvoiceWithUUID(params).then(response => response);
    },
    getViettelInvoiceDraft(ctx, invoiceId) {
      return useJwt.getViettelInvoiceDraft(invoiceId).then(response => response);

    },
    issueViettelInvoice(ctx, invoiceId) {
      return useJwt.issueViettelInvoice(invoiceId).then(response => response);
    },
    searchInvoice(ctx, params) {
      return useJwt.searchInvoice(params).then(response => response);
    },

    getInvoicePdf(ctx, invoiceId) {
      return useJwt.getInvoicePdf({ invoiceId }).then(response => response);
    },
    getInvoiceImage(ctx, invoiceId) {
      return useJwt.getInvoiceImage({ invoiceId }).then(response => response);
    },
    sendInvoiceViaZaloZNS(ctx, data) {
      return useJwt.sendInvoiceViaZaloZNS(data).then(response => response);
    },
    sendInvoiceViaZaloNormal(ctx, data) {
      return useJwt.sendInvoiceViaZaloNormal(data).then(response => response);
    },
    sendInvoiceViaZaloOA(ctx, data) {
      return useJwt.sendInvoiceViaZaloOA(data).then(response => response);
    },
    sendInvoiceViaApp(ctx, data) {
      return useJwt.sendInvoiceViaApp(data).then(response => response);
    },
    sendInvoiceViaEmail(ctx, data) {
      return useJwt.sendInvoiceViaEmail(data).then(response => response);
    },
    sendInvoices(ctx, data) {
      return useJwt.sendInvoices(data).then(response => response);
    },
    exportInvoices(ctx, params) {
      return useJwt.exportInvoices(params).then(response => response);
    },
    exportInvoicesAsZip(ctx, params) {
      return useJwt.exportInvoicesAsZip(params).then(response => response);
    },
    exportInvoicesAsZipPdf(ctx, params) {
      return useJwt.exportInvoicesAsZipPdf(params).then(response => response);
    },
    importInvoices(ctx, data) {
      return useJwt.importInvoices(data).then(response => response);
    },
    calculateQuantity(ctx, data) {
      return useJwt.calculateQuantity(data).then(response => response);
    },
    createInvoice(ctx, data) {
      return useJwt.createInvoice(data).then(response => response);
    },
    createInvoicePayment(ctx, data) {
      return useJwt.createInvoicPayment(data).then(response => response);
    },
    updateInvoice(ctx, data) {
      return useJwt.updateInvoice(data).then(response => response);
    },
    updateInvoiceTime(ctx, data) {
      return useJwt.updateInvoiceTime(data).then(response => response);
    },
    generateMultipleInvoices(ctx, data) {
      return useJwt.generateMultipleInvoices(data).then(response => response);
    },
    generateImportTemplate(ctx, data) {
      return useJwt.generateImportInvoiceTemplate(data).then(response => response);
    },
    deleteInvoices(ctx, data) {
      return useJwt.deleteInvoices(data).then(response => response);
    },
    approveInvoices(ctx, data) {
      return useJwt.approveInvoice(data).then(response => response);
    },
    getInvoiceDetail(ctx, id) {
      return useJwt.getInvoiceDetail(id).then(response => response);
    },
    getIncomeExpenseForInvoice(ctx, id) {
      return useJwt.getIncomeExpenseForInvoice(id).then(response => response);
    },
    getRemainPrepaidOfStudent(ctx, studentId) {
      return useJwt.getRemainPrepaidOfStudent(studentId).then(response => response);
    },
  },
};
